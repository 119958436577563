let Cardtype2=[
    {
        title:'CLEANING AND CRACK FILLING WITH SPECIAL CRACK FILL',
        price:'RS. 20/-',
    },
    {
        title:'EXTERNAL WATERPROOF CHEMICAL COATING :- (ASIAN PAINTS DAMPROOF)',
        price:'RS. 16/-',
    },
    {
        title:'ERECTION OF BAMBOO SCAFOLDING',
        price:'RS. 4/-',
    }
]

let Cardtype1=[
    {
        title:'ERRECTION OF BAMBOO SCAFOLDING',
        price:'RS. 4/-',
    },
    {
        title:'BREAKING OF OLD PLASTER AND REMOVING DEBRIS',
        price:'RS. 9/-',
    },
    {
        title:'POLYMER CEMENT MORTAR REPAIR',
        price:'RS. 130/-',
    },
    {
        title:'DOUBLE COAT FINE PLASTER',
        price:'RS. 90/-',
    },
]

let Cards=[
    Cardtype1,
    Cardtype2
]

export default Cards
